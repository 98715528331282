import { Api } from "@/apis";
export const allProps = {
  isShowBtn: {
    addBtn: true,
  },
  dialog: {
    isShow: false,
    title: "编辑",
  },
  data: { s_id: "" },
  items: [
    {
      label: "门店名",
      prop: "shopName",
      type: ["table"],
    },
    {
      label: "门店名",
      prop: "m_id",
      type: ["add", "edit"],
      Ftype: "slot",
    },
    {
      label: "设备号",
      prop: "s_id",
      type: ["table", "add", "edit"],
      Ftype: "slot",
    },
    {
      label: "游戏名",
      prop: "gameName",
      type: ["table"],
      Ftype: "select",
      list: [],
    },
    {
      label: "游戏名",
      prop: "game_id",
      type: [ "add", "edit"],
      Ftype: "select",
      list: [],
    },
    { label: "游戏定价(元)", prop: "money", type: ["table", "add", "edit"] },
    { label: "折扣(%)", prop: "discount", type: ["table", "add", "edit"] },
    {
      label: "设备方",
      prop: "supplier_hardware",
      type: ["add", "edit"],
      Ftype: "select",
      list: [],
    },
    {
      label: "场地方",
      prop: "supplier_field",
      type: ["add", "edit"],
      Ftype: "select",
      list: [],
    },
    {
      label: "内容方",
      prop: "supplier_content",
      type: [, "add", "edit"],
      Ftype: "select",
      list: [],
    },
    {
      label: "操作",
      prop: "operation",
      Ttype: "slot",
      type: ["table"],
      width: "150px",
    },
  ],
  api: Api.shopGame,
};
