<template>
  <div>
    <AllPage ref="AllPage" :allProps="allProps">
      <template v-slot:m_id="scope">
        <el-select
          v-model="scope.data.m_id"
          placeholder="请选择"
          filterable
          @change="clearSid(scope.data)"
        >
          <el-option
            v-for="(v, index) in shopList"
            :key="index"
            :label="v.ename"
            :value="v.id"
          />
        </el-select>
      </template>
      <template v-slot:s_id="scope">
        <el-select v-model="scope.data.s_id" placeholder="请选择" filterable>
          <el-option
            v-for="(v, index) in equipmentList"
            :key="index"
            :label="v.s_id"
            :value="v.id"
            @change="changeSid(scope.data)"
          />
        </el-select>
      </template>
      <template v-slot:operation="scope">
        <el-button
          size="small"
          @click.native.prevent="
            dialogForm('编辑', scope.data), getEquipmentList()
          "
        >
          编辑
        </el-button>
        <el-button
          size="small"
          @click.native.prevent="deleteRow(scope.data.id)"
        >
          删除
        </el-button>
      </template>
    </AllPage>
  </div>
</template>
<script>
import { Api } from "@/apis";
import { allProps } from "./config";
import { messageBox } from "@/utils/messageBox";
export default {
  components: {
    AllPage: () => import("@/components/AllPage/AllPage.vue"),
  },
  provide() {
    return {
      changeRuleForm: this.changeRuleForm,
    };
  },
  data() {
    return {
      shopList: {},
      allProps,
      userInfo: { page: 1, pageSize: 100 },
      cur_m_id: null,
      equipmentListBefore: [],
      gameList: [],
      partnerList: [],
      equipmentList: [],
    };
  },
  created() {
    let { name, token } = JSON.parse(localStorage.userInfo);
    this.userInfo = { name, token, ...this.userInfo };
    this.getShopInfo();
    this.getEquipmentListBefore();
    this.getGameList();
    this.getPartners();
  },
  /* computed: {
    equipmentList() {
      return this.equipmentListBefore.filter((v) => v.m_id === this.cur_m_id);
    },
  }, */
  methods: {
    getEquipmentList() {
      this.equipmentList = this.equipmentListBefore.filter(
        (v) => v.m_id === this.cur_m_id
      );
    },
    changeSid(data) {
      data = { ...data };
      this.allProps.data = { ...this.allProps.data };
      this.$forceUpdate();
    },
    clearSid(data) {
      data = { ...data };
      this.cur_m_id = data.m_id;
      this.allProps.data.s_id = this.equipmentList[0] || "";
      this.allProps.data = { ...this.allProps.data };
      this.$forceUpdate();
      this.getEquipmentList();
    },
    getPartners() {
      let { page, pageSize, token, name } = this.userInfo;
      this.$axios(
        `${Api.shopGame.getPartners}?page=${page}&pageSize=${pageSize}&token=${token}&name=${name}`
      ).then((res) => {
        let hardware = res.data.data.hardware.map((item) => {
          return { name: item.partner_name, id: item.id };
        });
        let field = res.data.data.field.map((item) => {
          return { name: item.partner_name, id: item.id };
        });
        let content = res.data.data.content.map((item) => {
          return { name: item.partner_name, id: item.id };
        });
        this.partnerList = { hardware, field, content };
      });
    },
    getGameList() {
      this.$axios.post( Api.allGame.get, this.userInfo).then((res) => {
        if (res.data.code === "0") {
          this.gameList = res.data.data;
        }
        this.allProps.items.forEach((v) => {
          if (v.prop === "game_id") v.list = this.gameList;
          else if (v.prop === "supplier_hardware")
            v.list = this.partnerList.hardware;
          else if (v.prop === "supplier_field") v.list = this.partnerList.field;
          else if (v.prop === "supplier_content")
            v.list = this.partnerList.content;
        });
      });
    },
    getEquipmentListBefore() {
      let that = this;
      this.$axios.post(Api.equipment.get, this.userInfo).then(function (res) {
        if (res.data.code === "0") that.equipmentListBefore = res.data.data;
      });
    },
    getShopInfo() {
      this.$axios.post(Api.shop.get, this.userInfo).then((res) => {
        this.shopList = res.data.data;
      });
    },
    changeTableList(data) {
      data.forEach((item) => {
        item.shopName = item.shopinfo[0].name
        item.gameName = item.gamelist[0].name
        item.s_id = item.data?.s_id;
      });
    },
    dialogForm(title, row = {}) {
      this.cur_m_id = row.m_id;
      allProps.dialog = { isShow: true, title };
      allProps.data = { ...row };
    },
    changeRuleForm(data) {
      let newdata = {
        discount: data.discount,
        game_id: data.game_id,
        id: data.id,
        m_id: data.m_id,
        money: data.money,
        name: data.name,
        s_id: data.s_id,
        supplier_content: data.supplier_content,
        supplier_field: data.supplier_field,
        supplier_hardware: data.supplier_hardware,
        token: data.token,
      };
      return (data = newdata);
    },
    async deleteRow(id) {
      let result = await messageBox(Api.shopGame.dele, { id });
      if (result) this.$refs.AllPage.getTableList();
    },
  },
};
</script>
